import { useCallback } from 'react'
import { Router, Routes } from 'blitz'
import { Banner, List } from '@shopify/polaris'
import { ProductMissingFabricColor, ProductMissingPerspectiveImage, ProductMissingSize } from '../lib/auditProduct'

export function ProductAlertCard({ alerts }) {
  const title = alerts[0].shopifyProduct.title
  const id = alerts[0].shopifyProduct.id

  const renderedProductAudits = alerts.flatMap((auditResult) => {
    switch (auditResult.auditResult) {
      case 'ProductMissingFabricColor':
        return (
          <ProductMissingFabricColorCard key={auditResult.product.id + auditResult.color} color={auditResult.color} />
        )
      case 'ProductMissingPerspectiveImage':
        return (
          <MissingPerspectiveImageCard
            key={auditResult.product.id + auditResult.perspective.display_name}
            {...auditResult}
          />
        )
      case 'ProductMissingSize':
        return <MissingSizeCard key={auditResult.product.id + auditResult.size} {...auditResult} />
      default:
        return null
    }
  })

  const handleWarningAction = useCallback(() => Router.push(Routes.EditProductPage({ id })), [id])

  return (
    <Banner
      title={`Alerts for ${title}`}
      status="warning"
      action={{
        content: 'Review',
        onAction: handleWarningAction
      }}>
      <List>{renderedProductAudits}</List>
    </Banner>
  )
}

function MissingPerspectiveImageCard(auditResult: ProductMissingPerspectiveImage) {
  return (
    <>
      <List.Item>
        Missing perspective image: {auditResult.perspective?.display_name} / {auditResult.color}{' '}
      </List.Item>
    </>
  )
}

function MissingSizeCard(auditResult: ProductMissingSize) {
  return <List.Item>Dimensions not configured for size {auditResult.size}</List.Item>
}

function ProductMissingFabricColorCard({ color }) {
  return <List.Item>No fabric swatch for color {color}</List.Item>
}
